<template>
    <b-card title="General Detail">
        <validation-observer ref="simpleRules">
            <b-form>
                <b-row>
                    <b-col>
                        <h2>{{ customerDetail.customerNameId }}</h2>
                    </b-col>
                    <b-col>
                        <b-row>
                            <b-col md="3">
                                <img :src="profileImage" alt="" height="70px" />
                            </b-col>
                            <b-col md="9">
                                <!-- <b-form-file @change="handleImage" v-model="file" placeholder="Choose a file or drop it here..."
                                    drop-placeholder="Drop file here..." accept="image/*"  /> -->
                                <b-button v-b-toggle.sidebar-right variant="primary">Upload Profile Photo</b-button>
                            </b-col>
                        </b-row>

                    </b-col>
                </b-row>
                <hr>
                <b-row>
                    <b-col md="3">
                        <b-form-group label="Customer Prefix">
                            <v-select disabled v-model="customerDetail.namePrefix" :options="customerPrefixOption" />
                        </b-form-group>
                    </b-col>
                    <b-col md="3">
                        <b-form-group label="Customer Name *">
                            <validation-provider #default="{ errors }" name="Customer Name" rules="required">
                                <b-form-input v-model="customerDetail.name" :state="errors.length > 0 ? false : null"
                                    placeholder="Please Enter Customer Name" />
                            </validation-provider>
                        </b-form-group>
                    </b-col>
                    <b-col md="3">
                        <b-form-group label="Mobile No *">
                            <validation-provider #default="{ errors }" name="Mobile No"
                                rules="required|regex:^[6789]\d{9}$">
                                <b-form-input disabled v-model="customerDetail.mobileNo"
                                    :state="errors.length > 0 ? false : null" placeholder="Please Enter Mobile No" />
                            </validation-provider>
                        </b-form-group>
                    </b-col>
                    <b-col md="3">
                        <b-form-group label="Email">
                            <b-form-input disabled v-model="customerDetail.emailId" placeholder="Please Enter Email" />
                        </b-form-group>
                    </b-col>
                    <b-col md="3">
                        <b-form-group label="Address">
                            <b-form-textarea v-model="customerDetail.address" rows="2"
                                placeholder="Please Enter Address" />
                        </b-form-group>
                    </b-col>
                    <b-col md="3">
                        <b-form-group label="Area Location">
                            <b-form-textarea v-model="customerDetail.areaLocation" rows="2"
                                placeholder="Please Enter Area Location" />
                        </b-form-group>
                    </b-col>

                    <b-col md="3">
                        <b-form-group label="Customer Preference">
                            <v-select disabled v-model="customerDetail.customerPreference"
                                placeholder="Select Preference" :options="customerPreferenceOption" label="name" />
                        </b-form-group>
                    </b-col>
                    <b-col md="3">
                        <b-form-group label="Communication Preference">
                            <v-select disabled v-model="customerDetail.communicationPrefrence" label="name"
                                placeholder="Select Communication Preference"
                                :options="communicationPreferenceOption" />
                        </b-form-group>
                    </b-col>
                    <b-col md="3">
                        <b-form-group label="State">
                            <b-form-input v-model="customerDetail.state" placeholder="Please Enter State" />
                        </b-form-group>
                    </b-col>
                    <b-col md="3">
                        <b-form-group label="PinCode">
                            <b-form-input v-model="customerDetail.pinCode" placeholder="Please Enter Pin Code" />
                        </b-form-group>
                    </b-col>
                    <b-col md="3">
                        <b-form-group label="User Name">
                            <b-form-input disabled v-model="customerDetail.userName" placeholder="Please Enter UserName" />
                        </b-form-group>
                    </b-col>
                    <b-col md="3">
                        <b-form-group label="Customer GSTIN">
                            <b-form-input v-model="customerDetail.gstin" placeholder="Please Enter Customer GSTIN" />
                        </b-form-group>
                    </b-col>
                    <b-col md="3">
                        <b-form-group label="Barcode">
                            <b-form-input disabled v-model="customerDetail.barcode"
                                placeholder="Please Enter Barcode" />
                        </b-form-group>
                    </b-col>
                    <b-col md="3">
                        <b-form-group label="Birthday">
                            <flat-pickr v-model="customerDetail.birthday" class="form-control" name="date"
                                placeholder="Please Enter Birthday" />
                        </b-form-group>
                    </b-col>
                    <b-col md="3">
                        <b-form-group label="Anniversary Date">
                            <flat-pickr v-model="customerDetail.anniversary" class="form-control" name="date"
                                placeholder="Please Enter Anniversary Date" />
                        </b-form-group>
                    </b-col>
                    <b-col md="3">
                        <b-form-group label="Home Delivery">
                            <v-select disabled v-model="customerDetail.enabelHomeDelevery" label="name"
                                placeholder="Select Home Delivery" :options="homeDeliveryOption" />
                        </b-form-group>
                    </b-col>
                    <b-col md="3">
                        <b-form-group label="Marketing Source">
                            <b-form-input v-model="customerDetail.marketingSource"
                                placeholder="Please Enter Marketing Source" />
                        </b-form-group>
                    </b-col>
                    <b-col md="3">
                        <b-form-group label="Alternative Phone">
                            <b-form-input v-model="customerDetail.alternetPhone" placeholder="Please Enter Phone" />
                        </b-form-group>
                    </b-col>
                    <b-col md="3">
                        <b-form-group label="Facebook ID">
                            <b-form-input v-model="customerDetail.facebookId" placeholder="Please Enter Facebook ID" />
                        </b-form-group>
                    </b-col>
                    <b-col md="3">
                        <b-form-group label="Registration Source">
                            <v-select v-model="customerDetail.registrationSource" label="name"
                                placeholder="Enter Registration Source" :options="registrationSourceOption" />
                        </b-form-group>
                    </b-col>
                    <b-col cols="12">
                        <b-row>
                            <b-col class="text-center">
                                <b-button variant="primary" class="mt-1 mr-1" type="submit"
                                    @click.prevent="validationForm">
                                    Update</b-button>
                            </b-col>
                        </b-row>
                    </b-col>
                </b-row>
            </b-form>
        </validation-observer>
        <!-- <b-sidebar id="sidebar-right" right shadow bg-variant="white" width="80%" backdrop> -->
        <image-cropper :getProfileImage="getProfileImage"></image-cropper>
        <!-- </b-sidebar> -->
    </b-card>
</template>

<script>
import {
    BCard, BCol, VBToggle, BSidebar, BRow, BForm, BFormGroup, BFormInput, BFormTextarea, BButton, BCardText, BFormFile
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import flatPickr from 'vue-flatpickr-component'
import { required } from '@validations'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import api from '@/store/api'
import util from '@/store/utils'
import ImageCropper from './ImageCropper.vue'

export default {
    components: {
        ValidationProvider, ValidationObserver, BCard, BCardText, BFormFile,
        BCol, BSidebar,
        BRow,
        BForm,
        BFormGroup,
        BFormInput,
        BFormTextarea,
        BButton, VBToggle,
        vSelect,
        flatPickr, ImageCropper
    },
    directives: {
        'b-toggle': VBToggle,
    },
    data() {
        return {
            customerId: 0,
            customerPrefixOption: [],
            customerPrefix: 'Mr',
            customerName: '',
            mobileNo: '',
            emailId: '',
            address: '',
            areaLocation: '',
            state: '',
            pinCode: '',
            customerPreferenceOption: [],
            customerPreference: null,
            defaultDiscountPercentage: 0,
            communicationPreferenceOption: [],
            communicationPrefrence: null,
            gstin: '',
            membershipId: '',
            barcode: '',
            birthday: '',
            anniversary: '',
            remarks: '',
            homeDeliveryOption: util.homeDeliveryOptions(),
            homeDelivery: util.homeDelivery(),
            enabelHomeDelevery: false,
            marketingSource: '',
            alternetPhone: '',
            facebookId: '',
            registrationSourceOption: [],
            registrationSource: null,
            // password: '',
            messageOnSearchCustomer: '',
            messageOnGarmentDelivery: '',
            // generatedPassword: '',
            file: '',
            imageBase64: null,
            profileImage: '',
        }
    },
    props: {
        customerDetail: Array
    },
    methods: {
        getNamePrefixList() {
            let self = this;
            var axios = require('axios');
            var data = '';
            axios(api.getApi('get', '/masters/getNamePrefixList', data))
                .then(function (response) {
                    //console.log(JSON.stringify(response.data));
                    self.customerPrefixOption = response.data;
                })
                .catch(function (error) {
                    console.log(error);
                });
        },
        getRegistrationSource() {
            let self = this;

            var axios = require('axios');
            var data = '';
            axios(api.getApi('get', '/masters/getRegistrationSourceList', data))
                .then(function (response) {
                    let i=0;
                    for (let index = 0; index < response.data.length; index++) {
                        i++;
                        self.registrationSourceOption.push({'id':i,'name':response.data[index]})
                    }
                })
                .catch(function (error) {
                    console.log(error);

                });
        },
        getProfileImage(profileImage) {
            this.profileImage=util.convertImage(profileImage);
            this.customerDetail.profilePhoto = profileImage;
        },
        customerDetailOpen() {
            this.$router.push({ name: 'customerDetail', params: { id: this.customerDetail.id } })
        },
        validationForm() {
            this.$refs.simpleRules.validate().then(success => {
                if (success) {
                    let self = this;
                    // eslint-disable-next-line
                    // if (self.password == '') {
                    //     self.password = self.generatedPassword;
                    // }
                    if (self.customerDetail.enabelHomeDelevery == 'Yes') {
                        self.customerDetail.enabelHomeDelevery = true;
                    } else if (self.customerDetail.enabelHomeDelevery == 'No') {
                        self.customerDetail.enabelHomeDelevery = false;
                    }
                    if (self.customerDetail.communicationPrefrence == null) {
                        self.customerDetail.communicationPrefrence = null
                    } else {
                        if (self.customerDetail.communicationPrefrence.id == 0) {
                            self.customerDetail.communicationPrefrence = null
                        } else {
                            self.customerDetail.communicationPrefrence = { 'id': self.customerDetail.communicationPrefrence.id, 'name': self.customerDetail.communicationPrefrence.name };
                        }
                    }
                    if (self.customerDetail.customerPreference == null) {
                        self.customerDetail.customerPreference = null
                    } else {
                        self.customerDetail.customerPreference = { 'id': self.customerDetail.customerPreference.id, 'name': self.customerDetail.customerPreference.name };
                    }
                    if (self.customerDetail.registrationSource == null) {
                        self.customerDetail.registrationSource.id = 0;
                    }
                    var axios = require('axios');
                    var data = JSON.stringify(
                        {
                            "userName": self.customerDetail.userName,
                            //"password": self.password,
                            "namePrefix": self.customerDetail.namePrefix,
                            "name": self.customerDetail.name,
                            "mobileNo": self.customerDetail.mobileNo,
                            "emailId": self.customerDetail.emailId,
                            "address": self.customerDetail.address,
                            "areaLocation": self.customerDetail.areaLocation,
                            "state": self.customerDetail.state,
                            "pinCode": self.customerDetail.pinCode,
                            "customerPreference": self.customerDetail.customerPreference,
                            "communicationPrefrence": self.customerDetail.communicationPreference,
                            "gstin": self.customerDetail.gstin,
                            "barcode": self.customerDetail.barcode,
                            "birthday": self.customerDetail.birthday,
                            "anniversary": self.customerDetail.anniversary,
                            "enabelHomeDelevery": self.customerDetail.enabelHomeDelevery,
                            "marketingSource": self.customerDetail.marketingSource,
                            "alternetPhone": self.customerDetail.alternetPhone,
                            "facebookId": self.customerDetail.facebookId,
                            "registrationSource": self.customerDetail.registrationSource.id,
                            "profilePhoto": self.customerDetail.profilePhoto
                        }
                    );
                    axios(api.getApi('Put', '/customers/' + self.customerDetail.id, data))
                        .then(function (response) {
                            //console.log(JSON.stringify(response.data));
                            self.$swal({
                                title: '',
                                text: "Customer Updated Successfully!",
                                icon: 'success',
                                customClass: {
                                    confirmButton: 'btn btn-primary',
                                },
                                buttonsStyling: false,
                            })
                        })
                        .catch(function (error) {
                            console.log(error);
                        });
                } else {
                    this.$swal({
                        title: '',
                        text: 'Please Fill Mandatory Fields!',
                        icon: 'error',
                        customClass: {
                            confirmButton: 'btn btn-secondary',
                        },
                        buttonsStyling: false,
                    })
                }
            })
        },
        getCustomerDetails(id) {
            let self = this;
            var axios = require('axios');
            var data = '';

            axios(api.getApi('get', '/customers/' + id, data))
                .then(function (response) {
                    //console.log(JSON.stringify(response.data));
                    if (response.data.customerPreference == null) {
                        self.customerPreference = null;
                    } else {
                        self.customerPreference = response.data.customerPreference;
                    }
                    self.defaultDiscountPercentage = response.data.defaultDiscountPercentage;
                    if (response.data.communicationPrefrence == null) {
                        self.communicationPreference = null;
                    } else {
                        self.communicationPreference = response.data.communicationPrefrence;
                    }
                    if (response.data.enabelHomeDelevery == true) {
                        self.homeDelivery = 'Yes';
                    } else {
                        self.homeDelivery = 'No';
                    }
                    if (response.data.registrationSource > 0) {
                        const object1 = self.registrationSourceOption.find(obj => obj.id == response.data.registrationSource);
                        self.registrationSource = { 'id': object1.id, 'name': object1.name };
                    }
                    if (self.registrationSource == 0 || self.registrationSource == null) {
                        self.registrationSource = 0;
                    }

                    let customerNameId=0;
                    if (util.digits_count(response.data.id) == 3) {
                        customerNameId = "0" + response.data.id;
                    } else if (util.digits_count(response.data.id) == 2) {
                        customerNameId = "00" + response.data.id;
                    }
                    else if (util.digits_count(response.data.id) == 1) {
                        customerNameId = "000" + response.data.id;
                    }

                    self.customerDetail = {
                        'customerNameId':customerNameId,
                        'id': response.data.id,
                        'namePrefix': response.data.namePrefix,
                        'name': response.data.name,
                        'mobileNo': response.data.mobileNo,
                        'emailId': response.data.emailId,
                        'address': response.data.address,
                        'areaLocation': response.data.areaLocation,
                        'state': response.data.state,
                        'pinCode': response.data.pinCode,
                        'customerPreference': self.customerPreference,
                        'defaultDiscountPercentage': response.data.defaultDiscountPercentage,
                        'communicationPrefrence': response.data.communicationPrefrence,
                        'gstin': response.data.gstin,
                        'membershipId': response.data.membershipId,
                        'barcode': response.data.barcode,
                        'birthday': response.data.birthday,
                        'anniversary': response.data.anniversary,
                        'remarks': response.data.remarks,
                        'enabelHomeDelevery': self.homeDelivery,
                        'marketingSource': response.data.marketingSource,
                        'alternetPhone': response.data.alternetPhone,
                        'facebookId': response.data.facebookId,
                        'registrationSource': self.registrationSource,
                        'msgOnSearchCustomer': response.data.msgOnSearchCustomer,
                        'messageOnGarmentDelivery': response.data.msgOnGarmentDelevery,
                        'userName': response.data.userName,
                        'profilePhoto': response.data.profilePhoto
                    }
                    // if(self.customerDetail.profilePhoto=null){
                        self.profileImage = util.convertImage(self.customerDetail.profilePhoto)
                    // }else{
                    //     self.profileImage = util.convertImage(self.customerDetail.profilePhoto)
                    // }
                    //self.profileImage = util.convertImage(self.customerDetail.profilePhoto)
                })
                .catch(function (error) {
                    console.log(error);
                });
        },
        getCustomerPreferences() {
            let self = this;
            var axios = require('axios');
            var data = '';
            axios(api.getApi('get', '/masters/customerPreferences', data))
                .then(function (response) {
                    //console.log(JSON.stringify(response.data));
                    self.customerPreferenceOption = response.data;
                })
                .catch(function (error) {
                    console.log(error);
                });
        },
        getCommunicationPreference() {
            let self = this;

            var axios = require('axios');
            var data = '';
            axios(api.getApi('get', '/masters/communicationPreferences', data))
                .then(function (response) {
                    //console.log(JSON.stringify(response.data));

                    self.communicationPreferenceOption = response.data;
                })
                .catch(function (error) {
                    console.log(error);
                });
        },
    },
    created() {
        this.getNamePrefixList();
        this.getRegistrationSource();
        this.getCustomerPreferences();
        this.getCommunicationPreference();
        this.customerId = this.$store.state.userId;
        this.getCustomerDetails(this.$store.state.userId)
    }
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>